import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  state => state?.user,
  user => user.loading,
);

const getErrors = createSelector(
  state => state?.user,
  user => user.errors,
);

const getCurrentUser = createSelector(
  state => state?.user,
  user => user.currentUser,
);

const selectors = {
  getErrors,
  getCurrentUser,
  getLoadingState,
  ...adapter.getSelectors(state => state.user),
};

export default selectors;
