import React from 'react';
import './Message.scss';

const Message = ({ data, className, contactAdmin }) => {
  const handleContactRedirect = () => {
    window.open(process.env.REACT_APP_GS1_CONTACT || 'https://gs1eg.org/connect-with-us/');
  };
  return (
    <div className="top-message">
      <div className={className}>
        <div className="message-title">{data}</div>{' '}
        {contactAdmin ? (
          <div className="message-description">Please contact GS1 Administrators.</div>
        ) : (
          <div className="message-description">
            Please <a onClick={handleContactRedirect}>contact GS1</a> if you need help.
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
