import { Button, Col, Row } from 'antd';
import React, { useState, useContext } from 'react';
import { useMedia } from 'react-use';
import BreakpointsContext from '../../theme/context';

import './ResultsTableFilter.scss';

const filters = [
  { name: 'Start date', type: 'date', value: 'start_date' },
  { name: 'End date', type: 'date', value: 'end_date' },
  { name: 'Response Status', type: 'select', value: 'response_status' },
];

const responseStatusFilters = [
  { name: 'All', value: '' },
  { name: 'Success', value: 'success' },
  { name: 'Not found', value: 'not_found' },
  { name: 'Processing', value: 'processing' },
  { name: 'Failed', value: 'failed' },
];

const ResultsTableFilter = ({ filterResults }) => {
  const Appcontext = useContext(BreakpointsContext);
  const { breakpoints } = Appcontext;
  const large = useMedia(`(max-width: ${breakpoints.$lg})`);

  const [filtersState, setFiltersState] = useState({
    start_date: '',
    end_date: '',
    response_status: '',
  });

  const buttons = [
    {
      name: 'Filter',
      class: 'filter-button-style',
      action: () => filterResults(filtersState),
    },
    {
      name: 'Reset',
      class: 'reset-button-style',
      action: () => {
        const newState = { start_date: '', end_date: '', response_status: '' };
        setFiltersState(newState);
        filterResults(newState);
      },
    },
  ];

  const renderFilters = ({ filters, setFiltersState, filtersState }) => {
    const handleChange = (e, value) => {
      setFiltersState({
        ...filtersState,
        [value]: e.target.value,
      });
    };
    return (
      <Row gutter={[16, 16]} className="left-filters-styling">
        {filters.map((filter, index) => (
          <Col
            xl={7}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className={`col-padding-filters ${filter.type === 'date' && 'input-styling'}`}
            key={index}
          >
            <div>{filter.name}</div>
            <div>
              {filter.type === 'select' ? (
                <select
                  className=" select-styling"
                  value={filtersState[filter.value]}
                  onChange={e => handleChange(e, filter.value)}
                >
                  {responseStatusFilters.map(status => (
                    <option value={status.value}>{status.name}</option>
                  ))}
                </select>
              ) : (
                <input
                  className={`input ${large ? 'meduim-input' : ''}`}
                  type={filter.type}
                  value={filtersState[filter.value]}
                  onChange={e => handleChange(e, filter.value)}
                />
              )}
            </div>
          </Col>
        ))}
      </Row>
    );
  };
  const renderButtons = ({ buttons }) => {
    return (
      <Row justify="center">
        {buttons.map((button, index) => (
          <Col key={index}>
            <Row className={button.class}>
              <Button className="ant-btn" onClick={button.action}>
                {button.name}
              </Button>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Row className="row-filters">
      {renderFilters({ filters, setFiltersState, filtersState })}
      {renderButtons({ buttons, setFiltersState, filtersState })}
    </Row>
  );
};

export default ResultsTableFilter;
