import React from 'react';
import { Breadcrumb } from 'antd';
import './BreadCrumbs.scss';

const BreadCrumbs = ({ className, singleBreadcrumbClassName, values }) => {
  return (
    <div className="breadcrumb-wrapper">
      <Breadcrumb className={className}>
        {values?.map(item => {
          return (
            <Breadcrumb.Item className={singleBreadcrumbClassName} href={item.href}>
              {item.value}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
