import React from 'react';
import { Form, Input } from 'antd';
import './styles.scss';

const index = ({ type, onChange, required, message, label, value, name, className }) => {
  return (
    <Form.Item
      label={<div className="label">{label}</div>}
      name={name || type}
      requiredMark="optional"
      rules={[
        {
          required,
          message,
        },
      ]}
    >
      <Input className={className} onChange={onChange} value={value} type={type} />
    </Form.Item>
  );
};

export default index;
