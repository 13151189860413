import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './Loadable.scss';

const antIcon = <LoadingOutlined spin />;

const Loadable = ({ spinning, children }) => {
  return (
    <div className="spin-wrapper">
      <Spin spinning={spinning} indicator={antIcon}>
        {children}
      </Spin>
    </div>
  );
};

export default Loadable;
