import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import * as actions from './actions';
import selectors from './selectors';
import adapter from './adapter';
import { addToLocalStorage } from '../../utils/localStorageHelpers';
import { isEmpty } from 'lodash';
import { setLoadingState } from '../helpers';

const slice = createSlice({
  name: 'User',
  initialState: adapter.getInitialState({
    loading: false,
    currentUser: localStorage.getItem('currentUser') || {},
    errors: '',
  }),
  extraReducers: builder => {
    builder
      .addCase(thunks.signin.fulfilled, (state, action) => {
        state.currentUser = action.payload.data.user;
        addToLocalStorage('currentUser', action.payload.data.user);
        if (!isEmpty(action.payload.authHeaders)) {
          addToLocalStorage('authHeaders', action.payload.authHeaders);
        }
        state.errors = '';
        state.loading = false;
      })
      .addCase(thunks.userConfirmation.fulfilled, setLoadingState(false))
      .addCase(thunks.signin.pending, setLoadingState(true))
      .addCase(thunks.userConfirmation.pending, setLoadingState(true))
      .addCase(thunks.signin.rejected, (state, action) => {
        state.errors = action.payload.errors;
        state.loading = false;
      })
      .addCase(thunks.userConfirmation.rejected, (state, action) => {
        state.errors = action.payload.errors;
        state.loading = false;
      })
      .addCase(actions.setErrors, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(actions.signout, (state, action) => {
        state.currentUser = {};
        localStorage.removeItem('authHeaders');
        localStorage.removeItem('currentUser');
        state.errors = action.payload || '';
      });
  },
});

const user = {
  slice,
  thunks,
  actions,
  selectors,
};
export default user;
