import { createAsyncThunk } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { history } from '../../router';
import UserAPI from './services';

export const signin = createAsyncThunk('user/login', async (user, { rejectWithValue }) => {
  try {
    const response = await UserAPI.login(user);
    const authHeaders = pick(response.headers, ['client', 'expiry', 'token', 'uid']);
    return { data: response.data, authHeaders };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userConfirmation = createAsyncThunk('user/confirm', async (user, { rejectWithValue }) => {
  try {
    const response = await UserAPI.userConfirm(user);
    history.push('/login');
    return;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const ForgetPassword = createAsyncThunk('user/forgetpassword', async (user, { rejectWithValue }) => {
  try {
    const response = await UserAPI.forgetPassword(user);
    return response;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const ResetPassword = createAsyncThunk('user/resetpassword', async (user, { rejectWithValue }) => {
  try {
    const response = await UserAPI.resetPassword(user);
    return response;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
