import { createAsyncThunk } from '@reduxjs/toolkit';
import SearchResult from './services';
import { history } from '../../router';

export const fetchSearchResult = createAsyncThunk('searchResult/fetch', async (id, { rejectWithValue }) => {
  try {
    const response = await SearchResult.fetchSearchResult(id);
    return response.data.search_result;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const fetchSearchResponses = createAsyncThunk(
  'searchResponses/fetch',
  async (searchResponse, { rejectWithValue }) => {
    try {
      const response = await SearchResult.fetchSearchResponses(searchResponse);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchSearchResponse = createAsyncThunk(
  'searchResponse/fetch',
  async (searchResponse, { rejectWithValue }) => {
    try {
      const response = await SearchResult.fetchSearchResponse({
        id: searchResponse.id,
        searchResponseId: searchResponse.searchResponseId,
        type: searchResponse.type,
      });
      return response.data[`${searchResponse.type}_search_response`];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchSearchResults = createAsyncThunk('searchResults/fetch', async (params, { rejectWithValue }) => {
  try {
    const response = await SearchResult.fetchSearchResults({
      page: params.page,
      filterParams: params.filterParams || '',
    });
    return {
      results: response.data.search_results,
      meta: response.data.meta,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createSingleSearchResult = createAsyncThunk(
  'searchResultsSingle/create',
  async (gtin, { rejectWithValue }) => {
    try {
      const response = await SearchResult.createSingleSearchResult(gtin);
      const { search_result } = response.data;
      history.push(`/search_results/${search_result.id}/search_responses/single`);
      return search_result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBulkSearchResult = createAsyncThunk(
  'searchResultsBulk/create',
  async (gtins, { rejectWithValue }) => {
    try {
      const data = new FormData();
      Object.keys(gtins).map(key => {
        if (gtins[key]) return data.append(`search_result[${key}]`, gtins[key]);
      });
      data.append(`search_result[query_type]`, 'batch');
      const response = await SearchResult.createBulkSearchResult(data);
      const { search_result } = response.data;

      history.push(`/search_results/${search_result.id}/search_responses/bulk?page=1`);
      return search_result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
