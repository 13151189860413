import axios from 'axios';
import assign from 'lodash/assign';
import { loadFromLocalStorage } from './localStorageHelpers';
import store from '../redux/store';
import user from '../redux/user';
import { history } from '../router';
import { API_BASE_URL } from './constants';

axios.defaults.baseURL = API_BASE_URL;

const injectAuthHeaders = request => {
  const authHeaders = loadFromLocalStorage('authHeaders');
  assign(request.headers, authHeaders);
  return request;
};

axios.interceptors.request.use(injectAuthHeaders);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(user.actions.signout());
      history.push('/login');
    }
    if (error.response.status === 500) {
      history.push('/server_down');
    }
    return Promise.reject(error);
  },
);

export default axios;
