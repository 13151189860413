import React from 'react';
import './ResultsTable.scss';
import ResultsTablePagination from '../Common/Pagination';
import moment from 'moment';

const headerNames = ['Name', 'Description', 'Response Status', 'Validation Result', 'Created at', 'Action'];
const tableValues = ['query_type', 'description', 'response_status', 'validation_result', 'created_at'];
const renderDescription = ({ query_type, description }) => {
  if (query_type === 'single') {
    if (description) {
      return <td>{description[Object.keys(description)?.[0]]}</td>;
    }
  }
  return <td></td>;
};

const renderTableValues = record =>
  tableValues.map((element, index) => {
    const { query_type } = record;
    switch (element) {
      case 'query_type':
        return query_type === 'batch' ? (
          <td key={index}>Bulk Search</td>
        ) : (
          <td key={index}>{record.search_responses?.[0]?.gtin} </td>
        );
      case 'description':
        return renderDescription({ query_type, description: record.search_responses?.[0]?.product_description });
      case 'validation_result':
        return query_type === 'single' ? (
          record.response_status === 'success' ? (
            <td key={index}>true</td>
          ) : (
            <td key={index}>false</td>
          )
        ) : (
          <td key={index}>
            <div>Success: {record.success_items_count}</div>
            <div>Failed: {record.failure_items_count}</div>
          </td>
        );
      case 'created_at':
        return <td key={index}>{moment(record.created_at).format('DD/MM/YYYY hh:mm A')}</td>;
      default:
        return <td key={index}>{record[element]}</td>;
    }
  });

const ResultsTable = ({ SearchResults, meta }) => {
  return (
    <div className="results-table-margin">
      <table class="table table-hover table-striped search-result-main-table">
        <thead>
          <tr>
            {headerNames.map((headerName, index) => (
              <th key={index}>{headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {SearchResults?.map((element, index) => {
            return (
              <tr key={index} className={element.response_status !== 'success' && 'result-table-row-table'}>
                {renderTableValues(element)}
                {
                  <td>
                    {element.response_status !== 'not_found' && (
                      <a href={`/search_results/${element.id}/search_responses/${element.query_type}?page=1`}>Show</a>
                    )}
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      <ResultsTablePagination meta={meta} />
    </div>
  );
};

export default ResultsTable;
