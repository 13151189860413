import { Col } from 'antd';
import React from 'react';

const GCPLabelsAndNames = [
  { label: 'GTIN', name: 'gtin' },
  { label: 'Key', name: 'key' },
  { label: 'Licensee GLN', name: 'licensee_gln' },
  { label: 'MO name', name: 'mo_name' },
];

const GCPView = ({ searchResponse }) => {
  const renderTableData = ({ data }) =>
    data.map((element, index) => (
      <div key={index}>
        <tr>
          <td>{element.label}</td>
          <td>{searchResponse?.[element.name] || <div className="unknown">Unknown</div>}</td>
        </tr>
      </div>
    ));
  return (
    <Col span={12}>
      <table class="checker-container">
        {renderTableData({
          data: GCPLabelsAndNames,
        })}
      </table>
    </Col>
  );
};

export default GCPView;
