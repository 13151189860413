import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import * as actions from './actions';
import selectors from './selectors';
import adapter from './adapter';
import { setLoadingState } from '../helpers';

const slice = createSlice({
  name: 'SearchResult',
  initialState: adapter.getInitialState({
    loading: false,
    currentSearchResult: {},
    currentSearchResponse: {},
    errors: '',
  }),
  extraReducers: builder => {
    builder
      .addCase(thunks.fetchSearchResults.pending, setLoadingState(true))
      .addCase(thunks.fetchSearchResults.fulfilled, (state, action) => {
        state.searchResults = action.payload.results || [];
        state.meta = action.payload.meta;
        state.errors = '';
        state.loading = false;
      })
      .addCase(thunks.createBulkSearchResult.pending, setLoadingState(true))
      .addCase(thunks.createBulkSearchResult.fulfilled, setLoadingState(false))
      .addCase(thunks.createBulkSearchResult.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      })
      .addCase(thunks.createSingleSearchResult.pending, setLoadingState(true))
      .addCase(thunks.createSingleSearchResult.fulfilled, setLoadingState(false))
      .addCase(thunks.createSingleSearchResult.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors.barcode;
      })
      .addCase(thunks.fetchSearchResponses.fulfilled, (state, action) => {
        state.searchResponses = action.payload.search_responses;
        state.meta = action.payload.meta;
        state.errors = '';
        state.loading = false;
      })
      .addCase(thunks.fetchSearchResponse.fulfilled, (state, action) => {
        state.currentSearchResponse = action.payload;
        state.errors = '';
        state.loading = false;
      })
      .addCase(thunks.fetchSearchResult.fulfilled, (state, action) => {
        state.currentSearchResult = action.payload;
        state.errors = '';
        state.loading = false;
      })
      .addCase(actions.setErrors, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(thunks.fetchSearchResult.pending, setLoadingState(true))
  },
});

const searchResults = {
  slice,
  thunks,
  actions,
  selectors,
};
export default searchResults;
