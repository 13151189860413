import React from 'react';

const breakpoints = {
  $xxs: '360px',
  $xs: '480px',
  $sm: '576px',
  $md: '768px',
  $lg: '992px',
  $xl: '1200px',
  $xxl: '1600px',
};

const context = React.createContext({ breakpoints });

export default context;
