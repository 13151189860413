import { Row } from 'antd';
import React from 'react';
import './Footer.scss';
import Linkedin from '../../../assets/images/Linkedin.svg';
import Facebook from '../../../assets/images/Facebook.svg';
import Twitter from '../../../assets/images/Twitter.svg';
import Youtube from '../../../assets/images/Youtube.svg';

const links = [
  {
    img: Facebook,
    src: process.env.REACT_APP_GS1_FACEBOOK || 'https://www.facebook.com/GS1.Egypt',
  },
  {
    img: Twitter,
    src: process.env.REACT_APP_GS1_TWITTER || 'https://twitter.com/GS1Egypt',
  },
  {
    img: Youtube,
    src: process.env.REACT_APP_GS1_YOUTUBE || 'http://www.youtube.com/user/GS1EG',
  },
  {
    img: Linkedin,
    src:
      process.env.REACT_APP_GS1_LINKEDIN ||
      'http://www.linkedin.com/company/2308164?trk=tyah&amp;trkInfo=tarId1404915976913tasgs1eidx2-2-5',
  },
];

const copyRightsLinks = [
  {
    name: 'Privacy and policy',
    href: process.env.REACT_APP_GS1_PRIVACY_POLICY || 'https://gs1eg.org/privacy-policy/',
  },
  {
    name: 'Terms and conditions',
    href: process.env.REACT_APP_GS1_TERMS_AND_CONDITIONS || 'https://gs1eg.org/terms-conditions/',
  },
];

const Footer = () => {
  const date = new Date();
  const renderLink = ({ links }) => {
    return (
      <div className="icons-spacing">
        {links.map((link, index) => (
          <a href={link.src} target="_blank" key={index}>
            <img src={link.img} class="icon-links" alt="icon" />
          </a>
        ))}
      </div>
    );
  };

  const renderCopyRights = ({ links }) => {
    return (
      <>
        {links.map((link, index) => (
          <Row className="row" key={index}>
            <a class="copy-rights" href={link.href} key={index}>
              {link.name}
            </a>
            {index === 0 && <div className="line" />}
          </Row>
        ))}
      </>
    );
  };

  return (
    <div>
      <div className="footer-wrapper-top" />
      <div className="footer-wrapper" align="middle">
        <div className="footer-spacing">
          <div class="copy-rights-bold">© GS1 Egypt {date.getFullYear()}. All rights reserved</div>
        </div>
        <div className="footer-spacing">
          {renderCopyRights({
            links: copyRightsLinks,
          })}
        </div>

        {renderLink({ links })}
      </div>
    </div>
  );
};

export default Footer;
