import React from 'react';
import './CompleteMessage.scss';
import VerifiedBarcode from '../../../assets/images/verified-barcode.svg';

const CompleteMessage = ({ data, className, company_name = '' }) => {
  return (
    <div className="top-message">
      <div className={className}>
        <div className="logo">
          <img alt="logo" src={VerifiedBarcode} />
        </div>
        <div>
          <div className="massage-title">{data}</div>
          <div className="massage-description">
            This number is registered to
            <span className="message-description-copmany"> company: </span>
            {company_name ? company_name : 'Unknown'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteMessage;
