import React, { useContext, useState } from 'react';
import { Col, Row } from 'antd';
import './Navbar.scss';
import Logo from '../../../assets/images/GS1_logo.svg';
import { useLocation } from 'react-router';
import { useMedia } from 'react-use';
import BreakpointsContext from '../../../theme/context';
import userRedux from '../../../redux/user';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';

const optionsInHeader = [
  { name: 'Search', href: '/single_search', key: 'single_search' },
  { name: 'Bulk Search', href: '/bulk_search', key: 'bulk_search' },
  {
    name: 'Search Results',
    href: '/search_results?page=1',
    key: 'search_results',
  },
  {
    name: 'Logout',
    href: '/login',
    action: userRedux.actions.signout,
  },
];

const Navbar = () => {
  const Appcontext = useContext(BreakpointsContext);
  const { breakpoints } = Appcontext;
  const medium = useMedia(`(max-width: ${breakpoints.$lg})`);
  const [openMenu, setOpenMenu] = useState(false);
  const [prevStateOpenMenu, setPrevStateOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(userRedux.selectors.getCurrentUser);

  const { pathname } = useLocation();
  const renderHeaderOption = ({ headerOption }) => (
    <NavLink
      activeClassName={'active-link-style'}
      to={headerOption.href}
      className="option"
      onClick={() => {
        setOpenMenu(false);
        if (headerOption.action) {
          dispatch(headerOption.action());
        }
      }}
    >
      {headerOption.name}
    </NavLink>
  );

  const options = () => (
    <Row className="options">
      {optionsInHeader.map((headerOption, index) => (
        <Col key={index}>{renderHeaderOption({ headerOption })}</Col>
      ))}
    </Row>
  );

  const optionsMobile = () => (
    <div className="optionsMobile">
      {optionsInHeader.map((headerOption, index) => (
        <div
          className={`marginOptionsMobile ${pathname === headerOption.href && 'marginOptionsMobileSelected'}`}
          key={index}
        >
          {renderHeaderOption({ headerOption })}
        </div>
      ))}
    </div>
  );

  const openCloseMenu = () => {
    setPrevStateOpenMenu(openMenu);
    setOpenMenu(!openMenu);
  };

  const bars = () => (
    <div className="bars" onClick={openCloseMenu}>
      <div className="icon-bar"></div>
      <div className="icon-bar"></div>
      <div className="icon-bar"></div>
    </div>
  );

  const optionsSmallScreen = () => (
    <div className="optionsMobileDisplay">
      <div>{bars()}</div>
      {openMenu && !prevStateOpenMenu && <div className="menu-open">{optionsMobile()}</div>}
      {!openMenu && prevStateOpenMenu && <div className="menu-close">{optionsMobile()}</div>}
    </div>
  );

  const renderOptions = () => !isEmpty(currentUser) && (medium ? optionsSmallScreen() : options());

  return (
    <div className="navbar-static-top">
      <Row className="header-wrapper">
        <div className="right-nav-style">
          <img alt="logo" src={Logo} className="logo-style" />
          <span className="header-text">Verified by GS1</span>
        </div>
        {renderOptions()}
      </Row>
    </div>
  );
};

export default Navbar;
