import React from 'react';
import './notFound.scss';

const NotFound = () => {
  return (
    <div className="notfound">
      <div className="dialog">
        <div>
          <h1>The page you were looking for doesn't exist.</h1>
          <p>You may have mistyped the address or the page may have moved.</p>
        </div>
        <p>If you are the application owner check the logs for more information.</p>
      </div>
    </div>
  );
};

export default NotFound;
