import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from '../pages/Auth/Login';
import ForgetPassword from '../pages/Auth/ForgetPassword';

import Navbar from '../components/Common/Navbar/Navbar';
import FooterComponent from '../components/Common/Footer/Footer';
import SingleSearch from '../pages/Search/SingleSearch';
import BulkSearch from '../pages/Search/BulkSearch';
import SearchResults from '../pages/Search/SearchResults';
import SearchResponses from '../pages/Search/SearchResponses';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import NotFound from '../pages/NotFound';
import userRedux from '../redux/user';
import Confirm from '../pages/Auth/Confirm';
import { Layout } from 'antd';
import './style.scss';
import ResetPassword from '../pages/Auth/ResetPassword';
import serverDown from '../pages/ServerDown';

export const history = createBrowserHistory();

const Router = () => {
  const { Header, Footer, Content } = Layout;
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const currentUser = useSelector(userRedux.selectors.getCurrentUser);

  return (
    <Layout className="main-layout" style={{ minHeight: '100vh' }}>
      <Navbar />

      <Route
        render={() => (
          <>
            {!isEmpty(currentUser) ? (
              <Content>
                <div className={`main-content-style`}>
                  <Switch>
                    <Route exact path="/single_search" component={SingleSearch} />
                    <Route path="/bulk_search" component={BulkSearch} />
                    <Route exact path="/search_results" component={SearchResults} />
                    <Route path="/search_results/:id/search_responses/:type" component={SearchResponses} />
                    <Route
                      path="/search_results/:id/single/search_responses/:searchResponseId/:searchResponseType"
                      component={SearchResponses}
                    />
                    <Route exact path="/">
                      <Redirect to="/single_search" />
                    </Route>
                    <Route exact path="/login">
                      <Redirect to="/single_search" />
                    </Route>
                    <Route path="/server_down" component={serverDown} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </Content>
            ) : (
              <Content className="custom-main-style">
                <div className="main-content-style">
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/forget_password" component={ForgetPassword} />
                    <Route path="/checkers/confirmations/:token" component={Confirm} />
                    <Route path="/users/passwords/:token" component={ResetPassword} />

                    <Route exact path="/">
                      <Redirect to="/login" />
                    </Route>
                    <Route path="/server_down" component={serverDown} />

                    <Route component={NotFound} />
                  </Switch>
                </div>
              </Content>
            )}
          </>
        )}
      />

      <Footer>
        <FooterComponent />
      </Footer>
    </Layout>
  );
};

export default Router;
