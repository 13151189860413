import axios from '../../utils/requestHelpers';

const login = user => axios.post('v1/users/sign_in', user);
const forgetPassword = user => axios.post('v1/users/passwords', user);
const resetPassword = user => axios.put('v1/users/passwords', user);
const userConfirm = user => axios.put(`v1/users/confirmations`, user);

const UserAPI = {
  login,
  forgetPassword,
  resetPassword,
  userConfirm,
};

export default UserAPI;
