import React from 'react';
import './style.scss';

const serverDown = () => {
  return (
    <div className="serverDown">
      <div className="dialog">
        <div>
          <h1>Something Went Wrong!</h1>
        </div>
        <p>Please Try Again Later</p>
      </div>
    </div>
  );
};

export default serverDown;
