import React, { useContext, useEffect, useRef, useState } from 'react';
import './Bulk.scss';
import ImageNotAvailable from '../../../assets/images/image-not-available.png';
import Carousel from '../../Common/Carousel';
import { Checkbox, Input } from 'antd';
import { Link } from 'react-router-dom';
import Pagination from '../../Common/Pagination';
import Message from '../../Common/Message/Message';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '../../../hooks/useQueryParams';
import searchResultsRedux from '../../../redux/searchResult';
import { isEmpty, set } from 'lodash';
import Loadable from '../../Common/Loadable';
import BreadCrumbs from '../../Common/BreadCrumbs/BreadCrumbs';
import { useMedia } from 'react-use';
import BreakpointsContext from '../../../theme/context';
import BulkSearchCard from './BulkSearchCard';

const CSVHeaders = [
  'GTIN',
  'English Brand',
  'Arabic Brand',
  'Arabic Description',
  'English Description',
  'Company Name English',
  'Company Name Arabic',
  'Net Content',
  'Image Url',
];

const CSVValues = [
  'gtin',
  'brand_english',
  'brand_arabic',
  'product_description_english',
  'product_description_arabic',
  'company_name_english',
  'company_name_arabic',
  'net_content',
  'product_image',
];
const { Search } = Input;

const Bulk = () => {
  const Appcontext = useContext(BreakpointsContext);
  const { breakpoints } = Appcontext;
  const medium = useMedia(`(max-width: ${breakpoints.$lg})`);
  const dispatch = useDispatch();
  const currentSearchResult = useSelector(searchResultsRedux.selectors.getCurrentSearchResult);
  const searchResponses = useSelector(searchResultsRedux.selectors.getSearchResponses);
  const meta = useSelector(searchResultsRedux.selectors.getMeta);
  const { params, searchParams } = useQueryParams();
  const id = params.get('id');
  const page = searchParams?.page;
  const interval = useRef();
  const [completedDataOnly, setCompletedDataOnly] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const onFilter = () => {
    dispatch(
      searchResultsRedux.thunks.fetchSearchResponses({
        id,
        params: { complete_data_only: !completedDataOnly, query: searchQuery },
      }),
    );
    setCompletedDataOnly(!completedDataOnly);
  };

  const onSearch = () => {
    dispatch(
      searchResultsRedux.thunks.fetchSearchResponses({
        id,
        params: { query: searchQuery, complete_data_only: completedDataOnly },
      }),
    );
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      if (currentSearchResult?.response_status === 'processing')
        dispatch(searchResultsRedux.thunks.fetchSearchResult(id));
    }, 5000);
    return () => clearInterval(interval.current);
  }, [currentSearchResult?.response_status === 'processing']);

  useEffect(() => {
    if (!isEmpty(currentSearchResult) && currentSearchResult?.response_status !== 'processing') {
      dispatch(searchResultsRedux.thunks.fetchSearchResponses({ id, params: { page } }));
      clearInterval(interval.current);
    }
  }, [currentSearchResult?.response_status]);

  const renderMessage = () =>
    currentSearchResult?.failure_items_count > 0 && (
      <Message data={'Some of your search terms had errors.'} className="error" />
    );

  const renderSearchResultError = () =>
    currentSearchResult?.error_message && (
      <Message
        data={
          <div>
            <span>{currentSearchResult?.error_message}</span>
          </div>
        }
        contactAdmin
        className="warning"
      />
    );

  const renderExportResults = () => (
    <div className="export-results-link">
      <a href={currentSearchResult?.response_file_url} target="_blank" rel="noopener noreferrer">
        Export these results
      </a>
    </div>
  );

  const BreadcrumbsValues = [
    {
      href: '/search_results',
      value: 'Search Results',
    },
    {
      href: '',
      value: currentSearchResult.id,
    },
    {
      href: '',
      value: 'Search Response',
    },
  ];

  const TableData = () => {
    const { params } = useQueryParams();
    const id = params.get('id');
    const dispatch = useDispatch();
    const onRecommendedSearch = gtin => {
      dispatch(searchResultsRedux.thunks.createSingleSearchResult(gtin));
    };

    const productLabelsAndNames = [
      { label: 'Product image', name: 'product_image' },
      { label: 'Description', name: 'product_description' },
      { label: 'GTIN', name: 'gtin' },
    ];

    const tableBody = () => {
      const failedStatuses = ['not_found', 'failed'];
      return (
        <>
          {searchResponses?.map((element, index) => (
            <tr className={failedStatuses.includes(element.status) && 'record-error'} key={index}>
              {productLabelsAndNames.map((name, index) => {
                console.log(element);
                if (element.status === 'failed') {
                  let validationError = element.error_message.validationErrors;
                  let errorValue = validationError;
                  if (!!validationError.match(/<.*>/)) {
                    const gtin = validationError.replace(/<.*/, '');
                    const gtins = validationError.match(/<.*>/)[0].replace(/<|>/g, '');
                    errorValue = (
                      <div>
                        {gtin} <a onClick={() => onRecommendedSearch(gtins)}>{gtins}</a> ?
                      </div>
                    );
                  }
                  return (
                    <td>
                      {index === 0 ? (
                        <img alt="" className="img" src={ImageNotAvailable} />
                      ) : typeof element[name.name] === 'object' ? (
                        <div>{errorValue}</div> || '-'
                      ) : (
                        element[name.name] || ''
                      )}
                    </td>
                  );
                }
                if (element.status === 'not_found') {
                  return (
                    <td>
                      {index === 0 ? (
                        <img alt="" className="img" src={ImageNotAvailable} />
                      ) : (
                        element[name.name] || 'Not Found'
                      )}
                    </td>
                  );
                }
                return (
                  <td>
                    <Link to={`/search_results/${id}/single/search_responses/${element.id}/${element.type}`}>
                      {index === 0 ? (
                        <Carousel images={element.product_image} className="image-bulk-container" />
                      ) : typeof element[name.name] === 'object' ? (
                        element[name.name][Object.keys(element[name.name])[0]] || '-'
                      ) : (
                        element[name.name] || ''
                      )}
                    </Link>
                  </td>
                );
              })}
            </tr>
          ))}
        </>
      );
    };

    return (
      <div>
        <div>
          <table className="table table-hover table-striped bulk-search-main-table">
            <thead>
              <tr>
                {productLabelsAndNames.map((element, index) => (
                  <th key={index}>{element.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>{tableBody()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const showTable = () => {
    if (medium) {
      return <BulkSearchCard SearchResults={searchResponses} id={id} />;
    }
    return TableData();
  };

  return (
    <div>
      <Loadable spinning={currentSearchResult?.response_status === 'processing'}>
        <div className="bulk-wrapper">
          {/* <div className="bulk-search-subtitle">Search responses</div> */}
          <BreadCrumbs singleBreadcrumbClassName="single-breadcrumb-style" values={BreadcrumbsValues} />
          {renderExportResults()}
          {renderSearchResultError()}
          {renderMessage()}
          <div className="search_filter_div">
            <Checkbox onClick={onFilter}>Show only products with complete data</Checkbox>
            <Search
              placeholder="Search"
              enterButton="Search"
              size="large"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onSearch={onSearch}
              disabled={false}
            />
          </div>
          {showTable()}
        </div>
        <Pagination meta={meta} />
      </Loadable>
    </div>
  );
};

export default Bulk;
