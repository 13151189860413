import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Loadable from '../../components/Common/Loadable';
import userRedux from '../../redux/user';
import useQueryParams from '../../hooks/useQueryParams';
import Message from '../../components/Common/Message/Message';
import Field from '../../components/Common/Fields';
import { history } from '../../router';

import './Style.scss';

const ResetPassword = () => {
  const { params } = useQueryParams();
  const token = params.get('token');
  const [errors, setError] = useState();
  const dispatch = useDispatch();
  const userLoading = useSelector(userRedux.selectors.getLoadingState);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const submit = () => {
    if (password !== passwordConfirmation) {
      setError('Passwords must match');
    } else {
      setError();
      dispatch(
        userRedux.thunks.ResetPassword({
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: token,
        }),
      );
      history.push('/login');
    }
  };

  const handleChangePassword = e => {
    setPassword(e.target.value);
  };
  const handleChangePasswordConfirmation = e => {
    setPasswordConfirmation(e.target.value);
  };

  return (
    <div className="wrapper">
      <Loadable spinning={userLoading}>
        <div className="top-header">
          <div>Reset Password</div>
          <div>Enter your New password</div>
        </div>
        <Row className="form-centered background">
          <Col className="col-width">
            {errors && <Message data={errors} className="error" />}
            <Form layout="vertical" onFinish={submit}>
              <Field
                type="password"
                required
                label="password"
                value={password}
                onChange={handleChangePassword}
                message="Password is required"
              />
              <Field
                type="password"
                required
                label="password confirmation"
                value={passwordConfirmation}
                onChange={handleChangePasswordConfirmation}
                message="Password confirmation is required"
                name="confirm password"
              />
              <Form.Item className="buttons">
                <Button htmlType="submit">Confirm</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Loadable>
    </div>
  );
};

export default ResetPassword;
