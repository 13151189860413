import React, { useState } from 'react';
import { Row, Form, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Field from '../../components/Common/Fields';
import userRedux from '../../redux/user';
import Message from '../../components/Common/Message/Message';
import Loadable from '../../components/Common/Loadable';
import { history } from '../../router';

import './Style.scss';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('');

  const userLoading = useSelector(userRedux.selectors.getLoadingState);
  const dispatch = useDispatch();

  const submit = async () => {
    const ForgetPassword = await dispatch(userRedux.thunks.ForgetPassword({ email }));
    if (ForgetPassword.type === 'user/forgetpassword/fulfilled') {
      history.push('/login');
    } else {
      setErrors('please enter a valid email');
    }
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  return (
    <div className="wrapper">
      <Loadable spinning={userLoading}>
        <div className="top-header">
          <div>Forget Password</div>
          <div>Enter your email to reset your password</div>
        </div>
        <Row className="form-centered background">
          <Col className="col-width">
            {errors && <Message data={errors} className="error" />}
            <Form layout="vertical" onFinish={submit}>
              <Field
                className="Field"
                type="email"
                required={true}
                label="Email"
                value={email}
                onChange={handleChangeEmail}
              />

              <Form.Item className="buttons">
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Loadable>
    </div>
  );
};

export default ForgetPassword;
