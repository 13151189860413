import React from 'react';
import './styles.scss';
import { Carousel } from 'antd';
import ImageNotAvailable from '../../../assets/images/image-not-available.png';

export const CarouselComponent = ({ images, className }) => {
  if (images) {
    return (
      <Carousel className={`image-container ${className}`} slide={true} dots={{ className: 'dots-style' }} autoplay>
        {Object.keys(images)?.map(key => (
          <div>
            <div className="text-position">{key}</div>
            <img src={images[key] || ImageNotAvailable} alt="product" />
          </div>
        ))}
      </Carousel>
    );
  }
  return <img className="img" src={ImageNotAvailable} alt="product" />;
};

export default CarouselComponent;
