import { Col, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import './SingleSearch.scss';
import CheckSVG from '../../assets/images/check.svg';
import { useDispatch, useSelector } from 'react-redux';
import searchResultsRedux from '../../redux/searchResult';
import Message from '../../components/Common/Message/Message';
import Loadable from '../../components/Common/Loadable';
import { unwrapResult } from '@reduxjs/toolkit';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

const SingleSearch = () => {
  const dispatch = useDispatch();
  const [gtin, setGtin] = useState('');
  const [flag, setFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [resultsError, setResultsError] = useState();
  const searchResultsLoading = useSelector(searchResultsRedux.selectors.getLoadingState);
  const searchResultsErrors = useSelector(searchResultsRedux.selectors.getErrors);

  useEffect(() => {
    if (!!searchResultsErrors[0]?.match(/<.*>/)) {
      const gtin = searchResultsErrors[0]?.replace(/<.*/, '');
      const gtins = searchResultsErrors[0]?.match(/<.*>/)[0].replace(/<|>/g, '');
      setResultsError(
        <div>
          {gtin} <a onClick={() => onRecommendedSearch(gtins)}>{gtins}</a> ?
        </div>,
      );
    } else {
      setResultsError(searchResultsErrors);
    }
  }, [searchResultsErrors]);

  const handleSearch = () => {
    dispatch(searchResultsRedux.thunks.createSingleSearchResult(gtin))
      .then(unwrapResult)
      .then(_ => {})
      .catch(() => {
        setCurrentPage(true);
      });
  };

  const searchButton = (
    <div className="search-button" onClick={handleSearch}>
      Search
    </div>
  );

  const handleChangeGtin = e => {
    setGtin(e.target.value);
  };

  const onExampleSearch = () => {
    setGtin(6222014336140);
    setFlag(true);
  };

  const onRecommendedSearch = gtin => {
    setGtin(gtin);
    setFlag(true);
  };

  useEffect(() => {
    flag &&
      dispatch(searchResultsRedux.thunks.createSingleSearchResult(gtin))
        .then(unwrapResult)
        .then(_ => {})
        .catch(() => {
          setCurrentPage(true);
        });
  }, [dispatch, flag, gtin]);

  return (
    <div>
      <Loadable spinning={searchResultsLoading}>
        <div className="background-single-search">
          <img src={CheckSVG} className="check-svg-transparent" />

          <div className="check-svg-large" color="transparent" />

          <div className="description">Verify a product's identity based on trusted information from manufacturers</div>
          <img src={CheckSVG} className="check-svg-large" />
        </div>

        <div className="single-search">
          <Col>
            <div class="card-title">Enter a barcode number</div>
            <div className="search-wrapper">
              <Col>
                <Form.Item className="formItem">
                  <Input
                    prefix={<SearchOutlined />}
                    addonAfter={searchButton}
                    onPressEnter={handleSearch}
                    placeholder="Search"
                    value={gtin}
                    onChange={handleChangeGtin}
                  />
                </Form.Item>
                <div className="example-search">
                  Example Search:{' '}
                  <a onClick={onExampleSearch} className="example-search">
                    6222014336140
                  </a>
                </div>
              </Col>
              <div className="checks-alignment">
                <div>
                  <img src={CheckSVG} className="check-svg" />
                  Easily Identify products
                </div>
                <div>
                  <img src={CheckSVG} className="check-svg" />
                  Data provided only by manufacturers
                </div>
              </div>
            </div>
            {currentPage && searchResultsErrors && <Message data={resultsError} className="error" />}
          </Col>
        </div>
      </Loadable>
    </div>
  );
};

export default SingleSearch;
