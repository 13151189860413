import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

const useQueryParams = () => {
  const params = new URLSearchParams(useParams());
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  return { params, searchParams };
};

export default useQueryParams;
