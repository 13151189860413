import { Card, Col, Row } from 'antd';
import React from 'react';
import ResultsTablePagination from '../Common/Pagination';
import './ResultsTable.scss';
import moment from 'moment';

const CardsList = ({ SearchResults, meta }) => {
  const renderData = ({ data }) => {
    return (
      <>
        {data.map((element, index) => {
          return (
            <div key={index}>
              {element.label ? (
                <Row className="row-alignment">
                  <div className="title">{element.label}</div>
                  <div className="content">{element.content}</div>
                </Row>
              ) : (
                <Row className="content no-margin">{element.content}</Row>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderElement = element => [
    {
      label: 'Name:',
      content:
        element.query_type === 'batch' ? <div>Bulk Search</div> : <div>{element.search_responses?.[0]?.gtin}</div>,
    },
    {
      content: element.description,
    },
    {
      label: 'Response Status:',
      content: element.response_status,
    },
    {
      label: 'Validation Result:',
      content:
        element.query_type === 'single' ? (
          element.response_status === 'success' ? (
            'true'
          ) : (
            'false'
          )
        ) : (
          <div>
            <span>success: {element.success_items_count}</span>, <span>failed: {element.failure_items_count}</span>
          </div>
        ),
    },
    {
      label: 'Created at:',
      content: moment(element.created_at).format('DD/MM/YYYY hh:mm A'),
    },
  ];

  return (
    <div className="cards-list-margin">
      <Row className="list-margin-bottom" gutter={16}>
        {SearchResults?.map((element, index) => (
          <Col span={24} className="card-styling" key={index}>
            <Card title="" className={element.response_status !== 'success' && 'error'}>
              {renderData({
                data: renderElement(element),
              })}
              <Row>
                {element.response_status !== 'not_found' && (
                  <a
                    href={`/search_results/${element.id}/search_responses/${element.query_type}?page=1`}
                    className="content no-margin"
                  >
                    Show
                  </a>
                )}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <ResultsTablePagination meta={meta} />
    </div>
  );
};

export default CardsList;
