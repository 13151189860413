import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../router';
import user from './user';
import searchResults from './searchResult';

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    user: user.slice.reducer,
    searchResults: searchResults.slice.reducer,
  });

export default rootReducer;
