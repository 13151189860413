import React, { useContext, useEffect } from 'react';
import ResultsTable from '../../components/SearchResults/ResultsTable';
import CardsList from '../../components/SearchResults/CardsList';
import './SearchResults.scss';
import { useMedia } from 'react-use';
import BreakpointsContext from '../../theme/context';
import { useDispatch, useSelector } from 'react-redux';
import searchResultsRedux from '../../redux/searchResult';
import useQueryParams from '../../hooks/useQueryParams';
import ResultsTableFilter from '../../components/SearchResults/ResultsTableFilter';
import Loadable from '../../components/Common/Loadable';
import BreadCrumbs from '../../components/Common/BreadCrumbs/BreadCrumbs';

const BreadcrumbsValues = [
  {
    href: '',
    value: '',
  },
  {
    href: '',
    value: 'Search Results',
  },
];

const SearchResults = () => {
  const Appcontext = useContext(BreakpointsContext);
  const { breakpoints } = Appcontext;
  const medium = useMedia(`(max-width: ${breakpoints.$lg})`);
  const { searchParams } = useQueryParams();
  const dispatch = useDispatch();

  const meta = useSelector(searchResultsRedux.selectors.getMeta);
  const searchResultsLoading = useSelector(searchResultsRedux.selectors.getLoadingState);
  const searchResults = useSelector(searchResultsRedux.selectors.getSearchResults);

  useEffect(() => {
    dispatch(searchResultsRedux.thunks.fetchSearchResults({ page: searchParams?.page }));
  }, []);

  const filterResults = filters => {
    let filterParams = '';
    Object.keys(filters).map((key, index) => {
      if (filters[key]) filterParams = filterParams.concat(`&${key}=${filters[key]}`);
    });
    dispatch(
      searchResultsRedux.thunks.fetchSearchResults({
        page: searchParams?.page,
        filterParams,
      }),
    );
  };

  const renderTable = () => {
    if (medium) {
      return <CardsList SearchResults={searchResults} meta={meta} />;
    }
    return <ResultsTable SearchResults={searchResults} meta={meta} />;
  };

  return (
    <div className="search-results-styling">
      <Loadable spinning={searchResultsLoading || !searchResults}>
        <div className="search-results-title">Search Results</div>
        <ResultsTableFilter filterResults={filterResults} />
        {renderTable()}
      </Loadable>
    </div>
  );
};

export default SearchResults;
