import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Loadable from '../../components/Common/Loadable';
import userRedux from '../../redux/user';
import useQueryParams from '../../hooks/useQueryParams';
import Message from '../../components/Common/Message/Message';
import Field from '../../components/Common/Fields';

import './Style.scss';

const Confirm = () => {
  const { params } = useQueryParams();
  const token = params.get('token');
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const userErrors = useSelector(userRedux.selectors.getErrors);
  const userLoading = useSelector(userRedux.selectors.getLoadingState);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const submit = () => {
    if (password !== passwordConfirmation) {
      setError('Passwords must match');
    } else {
      setError();
      dispatch(
        userRedux.thunks.userConfirmation({
          user: {
            name,
            password,
            password_confirmation: passwordConfirmation,
          },
          confirmation_token: token,
        }),
      );
    }
  };

  const handleChangeName = e => {
    setName(e.target.value);
  };
  const handleChangePassword = e => {
    setPassword(e.target.value);
  };
  const handleChangePasswordConfirmation = e => {
    setPasswordConfirmation(e.target.value);
  };
  const formFields = [
    {
      type: 'string',
      required: true,
      label: 'name',
      value: name,
      onChange: handleChangeName,
      message: 'Name is required',
    },
    {
      type: 'password',
      required: true,
      label: 'password',
      value: password,
      onChange: handleChangePassword,
      message: 'Password is required',
    },
    {
      name: 'confirm password',
      type: 'password',
      required: true,
      label: 'password confirmation',
      value: passwordConfirmation,
      onChange: handleChangePasswordConfirmation,
      message: 'Password confirmation is required',
    },
  ];
  const formBody = () => formFields.map(field => <Field {...field} />);

  return (
    <div className="wrapper">
      <Loadable spinning={userLoading}>
        <div className="top-header">
          <div>Confirm user</div>
          <div>Enter your contact information to Confirm</div>
        </div>
        <Row className="form-centered background">
          <Col className="col-width">
            {(userErrors || error) && <Message data={userErrors || error} className="error" />}
            <Form layout="vertical" onFinish={submit}>
              {formBody()}
              <Form.Item className="buttons">
                <Button htmlType="submit">Confirm</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Loadable>
    </div>
  );
};

export default Confirm;
