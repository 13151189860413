import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.loading,
);

const getErrors = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.errors,
);

const getCurrentSearchResult = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.currentSearchResult,
);

const getCurrentSearchResponse = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.currentSearchResponse,
);

const getSearchResponses = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.searchResponses,
);

const getSearchResults = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.searchResults,
);

const getMeta = createSelector(
  state => state?.searchResults,
  searchResults => searchResults.meta,
);



const selectors = {
  getErrors,
  getCurrentSearchResult,
  getSearchResults,
  getLoadingState,
  getCurrentSearchResponse,
  getSearchResponses,
  getMeta,

  ...adapter.getSelectors(state => state.searchResults),
};

export default selectors;
