import axios from '../../utils/requestHelpers';

const fetchSearchResult = id => axios.get(`v1/users/search_results/${id}`);

const fetchSearchResponses = ({id, params}) => axios.get(`v1/users/search_results/${id}/search_responses`, {params});


const fetchSearchResponse = ({ id, searchResponseId, type }) =>
  axios.get(`v1/users/search_results/${id}/search_responses/${searchResponseId}?type=${type}`);

const fetchSearchResults = ({ page, filterParams }) =>
  axios.get(`v1/users/search_results/?page=${page}${filterParams}`);



const createSingleSearchResult = gtin =>
  axios.post('v1/users/search_results/', {
    search_result: { query: gtin, query_type: 'single' },
  });

const createBulkSearchResult = gtins => axios.post('v1/users/search_results/', gtins);

const SearchResultAPI = {
  fetchSearchResult,
  fetchSearchResults,
  createSingleSearchResult,
  createBulkSearchResult,
  fetchSearchResponses,
  fetchSearchResponse,
};

export default SearchResultAPI;
