import React, { useState } from 'react';
import { Row, Form, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Field from '../../components/Common/Fields';
import userRedux from '../../redux/user';
import Message from '../../components/Common/Message/Message';
import Loadable from '../../components/Common/Loadable';
import { history } from '../../router';

import './Style.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userErrors = useSelector(userRedux.selectors.getErrors);
  const userLoading = useSelector(userRedux.selectors.getLoadingState);
  const dispatch = useDispatch();
  const submit = async () => {
    const login = await dispatch(userRedux.thunks.signin({ email, password }));
    if (login.type === 'user/login/fulfilled') {
      history.push('/single_search');
    }
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };
  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  return (
    <div className="wrapper">
      <Loadable spinning={userLoading}>
        <div className="top-header">
          <div>LOG IN</div>
          <div>Enter your contact information to log in</div>
        </div>
        <Row className="form-centered background">
          <Col className="col-width">
            {userErrors && <Message data={userErrors} className="error" />}
            <Form layout="vertical" onFinish={submit}>
              <Field
                className="Field"
                type="email"
                required={true}
                label="Email"
                value={email}
                onChange={handleChangeEmail}
                message="Email is Required"
              />
              <Field
                className="Field"
                type="password"
                required={true}
                label="Password"
                value={password}
                onChange={handleChangePassword}
                message="Password is Required"
              />
              <div className="forget-password">
                <a href="/forget_password">Forget Password?</a>
              </div>
              <Form.Item className="buttons">
                <Button htmlType="submit">LOG IN</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Loadable>
    </div>
  );
};

export default Login;
