import { Col, Row } from 'antd';
import React from 'react';
import useQueryParams from '../../hooks/useQueryParams';

const Pagination = ({ meta }) => {
  const { searchParams } = useQueryParams();
  const { total_pages, next_page, previous_page } = meta || {};
  const currentPage = parseInt(searchParams?.page);
  const renderNumbers = () => {
    const numbers = Array.from({ length: total_pages || 0 }, (_, i) => i + 1);
    const startingNumber = numbers.slice(currentPage - 1, currentPage + 5);
    return (
      <div>
        {startingNumber.map(number => (
          <a className={currentPage === number ? 'active' : 'notActive'} href={`?page=${number}`}>
            {number}
          </a>
        ))}
      </div>
    );
  };
  const renderRightArrows = () =>
    total_pages !== 0 &&
    next_page && (
      <div>
        <a className="notActive arrows-no-border" href={`?page=${currentPage + 1}`}>
          <span className="glyphicon gs1icon-arrow_right"></span>
        </a>
        <a className="notActive arrows-no-border" href={`?page=${total_pages}`}>
          <span className="glyphicon gs1icon-arrow_right"></span>
          <span className="glyphicon gs1icon-arrow_right"></span>
        </a>
      </div>
    );
  const renderLeftArrows = () =>
    total_pages !== 0 &&
    previous_page && (
      <div>
        <a className="notActive arrows-no-border" href={`?page=1`}>
          <span className="glyphicon gs1icon-arrow_left"></span>
          <span className="glyphicon gs1icon-arrow_left"></span>
        </a>
        <a className="notActive arrows-no-border" href={`?page=${currentPage - 1}`}>
          <span className="glyphicon gs1icon-arrow_left"></span>
        </a>
      </div>
    );

  return (
    <Row className="pagination">
      {renderLeftArrows()}
      <Col>{renderNumbers()}</Col>
      {renderRightArrows()}
    </Row>
  );
};

export default Pagination;
